import * as itemTypes from '../../items/item-types';

export const ERROR_MESSAGES = {
  FETCH_FAILED: 'Failed to fetch suppression list',
  UPLOAD_PREP_FAILED: 'Failed to prepare upload. Please try again.',
  FILE_UPLOAD_FAILED:
    'Failed to upload file. Please check your connection and try again.',
  IMPORT_FAILED:
    'File uploaded but import process failed to start. Please contact support.',
  STATUS_UPDATE_FAILED:
    'Unable to monitor import status. Please refresh the page.',
  UPLOAD_FAILED: 'An unexpected error occurred during the upload process.',
  SAVE_FAILED: 'Save failed'
};

export const SUCCESS_MESSAGES = {
  UPLOAD_STARTED: 'File upload successful and import started',
  SAVE_SUCCESSFUL: 'Save successful'
};

export const IMPORT_STATUSES = {
  IN_PROGRESS: 'InProgress',
  CLEAN_UP: 'CleanUp',
  SUCCESSFUL: 'Successful',
  FAILED: 'Failed',
  NOT_STARTED: 'NotStarted'
};

export const STATUS_POLLING_INTERVAL = 10000; // 10 seconds
export const ALLOWED_FILE_TYPES = ['text/csv'];

// Base URL for suppression list API
export const API_BASE_URL = `/api/${itemTypes.ADVERTISER}`;
