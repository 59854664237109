import { createReducer } from '@reduxjs/toolkit';
import {
  setSuppressionIsBusy,
  setSuppressionList,
  setSuppressionLoading,
  setSuppressionModal,
  setSuppressionName,
  setEditingSuppression
} from './suppression.actions';

const initialState = {
  byId: {}, // For isBusy state
  suppressionList: [],
  isLoading: false,
  showModal: false,
  suppressionName: '',
  editingSuppression: null
};

const suppressionReducer = createReducer(initialState, builder => {
  builder
    .addCase(setSuppressionIsBusy, (state, action) => {
      const { id, isBusy } = action.payload;
      state.byId[id] = { ...state.byId[id], isBusy };
    })
    .addCase(setSuppressionList, (state, action) => {
      state.suppressionList = action.payload;
    })
    .addCase(setSuppressionLoading, (state, action) => {
      state.isLoading = action.payload;
    })
    .addCase(setSuppressionModal, (state, action) => {
      state.showModal = action.payload;
    })
    .addCase(setSuppressionName, (state, action) => {
      state.suppressionName = action.payload;
    })
    .addCase(setEditingSuppression, (state, action) => {
      state.editingSuppression = action.payload;
    });
});

export default suppressionReducer;
