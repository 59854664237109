import React, { createContext, useEffect, useReducer, useState } from 'react';
import { Box, Text, Tip } from 'grommet';
import {
  SELECT_MODAL_POSITION,
  SELECT_CLOSE_BUTTON_POSITION,
  SELECT_CLOSE_BUTTON_COLOR,
  SELECT_CLOSE_BUTTON_COLOR_STROKE,
  SELECT_MODAL_WIDTH,
  SELECT_MODAL_CORNER_SHAPE,
  SELECT_PAGE_OVERLAY_COLOR,
  SELECT_OFFER_BG_COLOR,
  AD_UNIT_THEME,
  SELECT_BUTTON_STYLE,
  SELECT_BUTTON_NEUTRAL_TEXT_STYLE,
  SELECT_BUTTON_POSITIVE_TEXT_STYLE,
  SELECT_BUTTON_NEGATIVE_TEXT_STYLE,
  SELECT_BUTTON_NEUTRAL_TEXT_WEIGHT,
  SELECT_BUTTON_POSITIVE_TEXT_WEIGHT,
  SELECT_BUTTON_NEGATIVE_TEXT_WEIGHT,
  SELECT_CTA_BUTTON_ORDER,
  SELECT_BUTTON_POSITIVE_BG_COLOR,
  SELECT_BUTTON_POSITIVE_TEXT_COLOR,
  SELECT_BUTTON_POSITIVE_BORDER_COLOR,
  SELECT_BUTTON_POSITIVE_BORDER_WIDTH,
  SELECT_BUTTON_POSITIVE_BORDER_STYLE,
  SELECT_BUTTON_NEGATIVE_BG_COLOR,
  SELECT_BUTTON_NEGATIVE_TEXT_COLOR,
  SELECT_BUTTON_NEGATIVE_BORDER_COLOR,
  SELECT_BUTTON_NEGATIVE_BORDER_WIDTH,
  SELECT_BUTTON_NEGATIVE_BORDER_STYLE,
  SELECT_BUTTON_NEUTRAL_BG_COLOR,
  SELECT_BUTTON_NEUTRAL_TEXT_COLOR,
  SELECT_BUTTON_NEUTRAL_BORDER_COLOR,
  SELECT_BUTTON_NEUTRAL_BORDER_WIDTH,
  SELECT_BUTTON_NEUTRAL_BORDER_STYLE,
  SELECT_BG_COLOR_ONE,
  SELECT_BG_COLOR_ONE_TEXT_COLOR,
  SELECT_BG_COLOR_TWO,
  SELECT_BG_COLOR_TWO_TEXT_COLOR,
  SELECT_BG_COLOR_THREE,
  SELECT_BG_COLOR_THREE_TEXT_COLOR,
  SELECT_BG_COLOR_FOUR,
  SELECT_BG_COLOR_FOUR_TEXT_COLOR,
  SELECT_TEXT_COLOR_ONE,
  SELECT_TEXT_COLOR_TWO,
  SELECT_TEXT_COLOR_THREE,
  SELECT_FONT_SIZE,
  SELECT_DEFAULT_FONT_FAMILY,
  SELECT_DEFAULT_FONT_COLOR,
  SELECT_SUPPORTING_FONT_FAMILY,
  SELECT_SUPPORTING_FONT_COLOR,
  SET_EDITING,
  SELECT_OFFER_IMAGE_PLACEMENT,
  SET_PREVIEW_MODE,
  PREVIEW_MODE_MOBILE,
  PREVIEW_MODE_DESKTOP,
  MOBILE_PREVIEW_CSS,
  SELECT_OFFER_BLOCK_IMG_FIRST,
  SELECT_OFFER_BLOCK_IMG_REMAINING,
  SELECT_OFFER_BLOCK_IMG_BORDER_RADIUS,
  SELECT_OFFER_BLOCK_IMG_BORDER_STYLE,
  SELECT_BORDER_COLOR_ONE,
  SELECT_BORDER_WIDTH_ONE,
  SELECT_BORDER_COLOR_TWO,
  SELECT_BORDER_WIDTH_TWO,
  SELECT_BORDER_COLOR_THREE,
  SELECT_BORDER_WIDTH_THREE,
  SELECT_BORDER_COLOR_FOUR,
  SELECT_BORDER_WIDTH_FOUR,
  SELECT_FOOTER_BORDER_COLOR,
  SELECT_FOOTER_BORDER_WIDTH,
  SELECT_FOOTER_BORDER_STYLE,
  SELECT_OFFER_BLOCK_IMG_BORDER_COLOR,
  SELECT_OFFER_BLOCK_IMG_BORDER_WIDTH,
  SELECT_BORDER_STYLE_ONE,
  SELECT_BORDER_STYLE_TWO,
  SELECT_BORDER_STYLE_THREE,
  SELECT_BORDER_STYLE_FOUR
} from './adunit-theme-constant';
import AdunitPreview from './adunit-preview';
import AdunitThemePanel from './adunit-theme-panel';
import {
  generateCssBlob,
  parseCssBlob,
  setStateFromCssBlob
} from '../../../traffic-partner-utils';
import Icon from '@mdi/react';
import { mdiMonitor, mdiCellphone } from '@mdi/js';
import ConfigurationTemplateButton from '../../configuration-template-button';
import AdUnitTemplatePickerModal from '../../ad-unit-template-picker-modal';

export const initialState = (customCss = []) => {
  const cssBlob =
    customCss === null
      ? []
      : typeof customCss === 'string'
      ? parseCssBlob(customCss)
      : customCss;

  return {
    // Modal Settings
    modalPosition:
      setStateFromCssBlob(AD_UNIT_THEME.modalPosition, cssBlob)[0] ||
      AD_UNIT_THEME.modalPosition[0],
    closeButtonPosition:
      setStateFromCssBlob(AD_UNIT_THEME.closeButtonPosition, cssBlob)[0] ||
      AD_UNIT_THEME.closeButtonPosition[2],
    closeButtonColor:
      setStateFromCssBlob(
        AD_UNIT_THEME.closeButton.bgColor().css.selector,
        AD_UNIT_THEME.closeButton.bgColor().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.closeButton.bgColor(),
    closeButtonColorStroke:
      setStateFromCssBlob(
        AD_UNIT_THEME.closeButton.strokeColor().css.selector,
        AD_UNIT_THEME.closeButton.strokeColor().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.closeButton.strokeColor(),
    modalWidth:
      setStateFromCssBlob(AD_UNIT_THEME.modalWidth, cssBlob)[0] ||
      AD_UNIT_THEME.modalWidth[2],
    modalCornerShape:
      setStateFromCssBlob(AD_UNIT_THEME.modalCornerShape, cssBlob)[0] ||
      AD_UNIT_THEME.modalCornerShape[1],
    pageOverlayColor:
      setStateFromCssBlob(
        AD_UNIT_THEME.pageOverlayColor().css.selector,
        AD_UNIT_THEME.pageOverlayColor().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.pageOverlayColor(),
    offerBackgroundColor:
      setStateFromCssBlob(
        AD_UNIT_THEME.offerBackgroundColor().css.selector,
        AD_UNIT_THEME.offerBackgroundColor().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.offerBackgroundColor(),
    offerImagePlacement:
      setStateFromCssBlob(AD_UNIT_THEME.offerImagePlacement, cssBlob)[0] ||
      AD_UNIT_THEME.offerImagePlacement[0],
    offerBlockImgFirst:
      setStateFromCssBlob(
        AD_UNIT_THEME.offerBlockImgFirst().css.selector,
        AD_UNIT_THEME.offerBlockImgFirst().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.offerBlockImgFirst(),
    offerBlockImgRemaining:
      setStateFromCssBlob(
        AD_UNIT_THEME.offerBlockImgRemaining().css.selector,
        AD_UNIT_THEME.offerBlockImgRemaining().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.offerBlockImgRemaining(),
    offerBlockImgBorderColor:
      setStateFromCssBlob(
        AD_UNIT_THEME.offerBlockImgBorderColor().css.selector,
        AD_UNIT_THEME.offerBlockImgBorderColor().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.offerBlockImgBorderColor(),
    offerBlockImgBorderWidth:
      setStateFromCssBlob(
        AD_UNIT_THEME.offerBlockImgBorderWidth().css.selector,
        AD_UNIT_THEME.offerBlockImgBorderWidth().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.offerBlockImgBorderWidth(),
    offerBlockImgBorderStyle:
      setStateFromCssBlob(AD_UNIT_THEME.offerBlockImgBorderStyle, cssBlob)[0] ||
      AD_UNIT_THEME.offerBlockImgBorderStyle[0],
    offerBlockImgBorderRadius:
      setStateFromCssBlob(
        AD_UNIT_THEME.offerBlockImgBorderRadius,
        cssBlob
      )[0] || AD_UNIT_THEME.offerBlockImgBorderRadius[0],

    // Fonts and Colors
    fontSize:
      setStateFromCssBlob(AD_UNIT_THEME.fontSizes, cssBlob)[0] ||
      AD_UNIT_THEME.fontSizes[4],
    defaultFontFamily:
      setStateFromCssBlob(AD_UNIT_THEME.defaultFontFamilies, cssBlob)[0] ||
      AD_UNIT_THEME.defaultFontFamilies[0],
    defaultFontColor:
      setStateFromCssBlob(
        AD_UNIT_THEME.defaultFontColor().css.selector,
        AD_UNIT_THEME.defaultFontColor().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.defaultFontColor(),
    supportingFontFamily:
      setStateFromCssBlob(AD_UNIT_THEME.supportingFontFamilies, cssBlob)[0] ||
      AD_UNIT_THEME.supportingFontFamilies[0],
    supportingFontColor:
      setStateFromCssBlob(
        AD_UNIT_THEME.supportingFontColor().css.selector,
        AD_UNIT_THEME.supportingFontColor().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.supportingFontColor(),

    // Button Settings
    buttonStyle:
      setStateFromCssBlob(AD_UNIT_THEME.buttons.styles, cssBlob)[0] ||
      AD_UNIT_THEME.buttons.styles[0],
    buttonNeutralTextStyle:
      setStateFromCssBlob(
        AD_UNIT_THEME.buttons.neutralTextStyles,
        cssBlob
      )[0] || AD_UNIT_THEME.buttons.neutralTextStyles[0],
    buttonPositiveTextStyle:
      setStateFromCssBlob(
        AD_UNIT_THEME.buttons.positiveTextStyles,
        cssBlob
      )[0] || AD_UNIT_THEME.buttons.positiveTextStyles[0],
    buttonNegativeTextStyle:
      setStateFromCssBlob(
        AD_UNIT_THEME.buttons.negativeTextStyles,
        cssBlob
      )[0] || AD_UNIT_THEME.buttons.negativeTextStyles[0],
    buttonNeutralTextWeight:
      setStateFromCssBlob(
        AD_UNIT_THEME.buttons.neutralTextWeight,
        cssBlob
      )[0] || AD_UNIT_THEME.buttons.neutralTextWeight[0],
    buttonPositiveTextWeight:
      setStateFromCssBlob(
        AD_UNIT_THEME.buttons.positiveTextWeight,
        cssBlob
      )[0] || AD_UNIT_THEME.buttons.positiveTextWeight[0],
    buttonNegativeTextWeight:
      setStateFromCssBlob(
        AD_UNIT_THEME.buttons.negativeTextWeight,
        cssBlob
      )[0] || AD_UNIT_THEME.buttons.negativeTextWeight[0],
    buttonCtaOrder:
      setStateFromCssBlob(AD_UNIT_THEME.buttons.ctaOrder, cssBlob)[0] ||
      AD_UNIT_THEME.buttons.ctaOrder[0],
    buttonPositiveBgColor:
      setStateFromCssBlob(
        AD_UNIT_THEME.buttons.positiveBgColor().css.selector,
        AD_UNIT_THEME.buttons.positiveBgColor().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.buttons.positiveBgColor(),
    buttonPositiveTextColor:
      setStateFromCssBlob(
        AD_UNIT_THEME.buttons.positiveTxtColor().css.selector,
        AD_UNIT_THEME.buttons.positiveTxtColor().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.buttons.positiveTxtColor(),
    buttonPositiveBorderColor:
      setStateFromCssBlob(
        AD_UNIT_THEME.buttons.positiveBorderColor().css.selector,
        AD_UNIT_THEME.buttons.positiveBorderColor().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.buttons.positiveBorderColor(),
    buttonPositiveBorderWidth:
      setStateFromCssBlob(
        AD_UNIT_THEME.buttons.positiveBorderWidth().css.selector,
        AD_UNIT_THEME.buttons.positiveBorderWidth().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.buttons.positiveBorderWidth(),
    buttonPositiveHover: AD_UNIT_THEME.buttons.positiveButtonHover,
    buttonPositiveActive: AD_UNIT_THEME.buttons.positiveButtonActive,
    buttonPositiveBorderStyle:
      setStateFromCssBlob(
        AD_UNIT_THEME.buttons.positiveBorderStyle,
        cssBlob
      )[0] || AD_UNIT_THEME.buttons.positiveBorderStyle[0],
    buttonNegativeBGColor:
      setStateFromCssBlob(
        AD_UNIT_THEME.buttons.negativeBgColor().css.selector,
        AD_UNIT_THEME.buttons.negativeBgColor().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.buttons.negativeBgColor(),
    buttonNegativeTextColor:
      setStateFromCssBlob(
        AD_UNIT_THEME.buttons.negativeTxtColor().css.selector,
        AD_UNIT_THEME.buttons.negativeTxtColor().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.buttons.negativeTxtColor(),
    buttonNegativeBorderColor:
      setStateFromCssBlob(
        AD_UNIT_THEME.buttons.negativeBorderColor().css.selector,
        AD_UNIT_THEME.buttons.negativeBorderColor().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.buttons.negativeBorderColor(),
    buttonNegativeBorderWidth:
      setStateFromCssBlob(
        AD_UNIT_THEME.buttons.negativeBorderWidth().css.selector,
        AD_UNIT_THEME.buttons.negativeBorderWidth().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.buttons.negativeBorderWidth(),
    buttonNegativeHover: AD_UNIT_THEME.buttons.negativeButtonHover,
    buttonNegativeActive: AD_UNIT_THEME.buttons.negativeButtonActive,
    buttonNegativeBorderStyle:
      setStateFromCssBlob(
        AD_UNIT_THEME.buttons.negativeBorderStyle,
        cssBlob
      )[0] || AD_UNIT_THEME.buttons.negativeBorderStyle[0],
    buttonNeutralBGColor:
      setStateFromCssBlob(
        AD_UNIT_THEME.buttons.neutralBgColor().css.selector,
        AD_UNIT_THEME.buttons.neutralBgColor().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.buttons.neutralBgColor(),
    buttonNeutralTextColor:
      setStateFromCssBlob(
        AD_UNIT_THEME.buttons.neutralTxtColor().css.selector,
        AD_UNIT_THEME.buttons.neutralTxtColor().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.buttons.neutralTxtColor(),
    buttonNeutralBorderColor:
      setStateFromCssBlob(
        AD_UNIT_THEME.buttons.neutralBorderColor().css.selector,
        AD_UNIT_THEME.buttons.neutralBorderColor().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.buttons.neutralBorderColor(),
    buttonNeutralBorderWidth:
      setStateFromCssBlob(
        AD_UNIT_THEME.buttons.neutralBorderWidth().css.selector,
        AD_UNIT_THEME.buttons.neutralBorderWidth().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.buttons.neutralBorderWidth(),
    buttonNeutralHover: AD_UNIT_THEME.buttons.neutralButtonHover,
    buttonNeutralActive: AD_UNIT_THEME.buttons.neutralButtonActive,
    buttonNeutralBorderStyle:
      setStateFromCssBlob(
        AD_UNIT_THEME.buttons.neutralBorderStyle,
        cssBlob
      )[0] || AD_UNIT_THEME.buttons.neutralBorderStyle[0],

    // Background & Font Color
    backgroundColorOne:
      setStateFromCssBlob(
        AD_UNIT_THEME.bgColors.bgColorOne().css.selector,
        AD_UNIT_THEME.bgColors.bgColorOne().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.bgColors.bgColorOne(),
    backgroundColorOneTextColor:
      setStateFromCssBlob(
        AD_UNIT_THEME.bgColors.bgColorOneTxtColor().css.selector,
        AD_UNIT_THEME.bgColors.bgColorOneTxtColor().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.bgColors.bgColorOneTxtColor(),
    backgroundColorTwo:
      setStateFromCssBlob(
        AD_UNIT_THEME.bgColors.bgColorTwo().css.selector,
        AD_UNIT_THEME.bgColors.bgColorTwo().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.bgColors.bgColorTwo(),
    backgroundColorTwoTextColor:
      setStateFromCssBlob(
        AD_UNIT_THEME.bgColors.bgColorTwoTxtColor().css.selector,
        AD_UNIT_THEME.bgColors.bgColorTwoTxtColor().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.bgColors.bgColorTwoTxtColor(),
    backgroundColorThree:
      setStateFromCssBlob(
        AD_UNIT_THEME.bgColors.bgColorThree().css.selector,
        AD_UNIT_THEME.bgColors.bgColorThree().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.bgColors.bgColorThree(),
    backgroundColorThreeTextColor:
      setStateFromCssBlob(
        AD_UNIT_THEME.bgColors.bgColorThreeTxtColor().css.selector,
        AD_UNIT_THEME.bgColors.bgColorThreeTxtColor().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.bgColors.bgColorThreeTxtColor(),
    backgroundColorFour:
      setStateFromCssBlob(
        AD_UNIT_THEME.bgColors.bgColorFour().css.selector,
        AD_UNIT_THEME.bgColors.bgColorFour().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.bgColors.bgColorFour(),
    backgroundColorFourTextColor:
      setStateFromCssBlob(
        AD_UNIT_THEME.bgColors.bgColorFourTxtColor().css.selector,
        AD_UNIT_THEME.bgColors.bgColorFourTxtColor().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.bgColors.bgColorFourTxtColor(),

    // Text Colors
    textColorOne:
      setStateFromCssBlob(
        AD_UNIT_THEME.textColors.textColorOne().css.selector,
        AD_UNIT_THEME.textColors.textColorOne().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.textColors.textColorOne(),
    textColorTwo:
      setStateFromCssBlob(
        AD_UNIT_THEME.textColors.textColorTwo().css.selector,
        AD_UNIT_THEME.textColors.textColorTwo().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.textColors.textColorTwo(),
    textColorThree:
      setStateFromCssBlob(
        AD_UNIT_THEME.textColors.textColorThree().css.selector,
        AD_UNIT_THEME.textColors.textColorThree().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.textColors.textColorThree(),
    isEditing: false,
    previewMode: PREVIEW_MODE_DESKTOP,
    // Borders and Dividers
    borderColorOne:
      setStateFromCssBlob(
        AD_UNIT_THEME.borders.borderColorOne().css.selector,
        AD_UNIT_THEME.borders.borderColorOne().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.borders.borderColorOne(),
    borderWidthOne:
      setStateFromCssBlob(
        AD_UNIT_THEME.borders.borderWidthOne().css.selector,
        AD_UNIT_THEME.borders.borderWidthOne().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.borders.borderWidthOne(),
    borderStyleOne:
      setStateFromCssBlob(AD_UNIT_THEME.borders.borderStyleOne, cssBlob)[0] ||
      AD_UNIT_THEME.borders.borderStyleOne[0],
    borderColorTwo:
      setStateFromCssBlob(
        AD_UNIT_THEME.borders.borderColorTwo().css.selector,
        AD_UNIT_THEME.borders.borderColorTwo().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.borders.borderColorTwo(),
    borderWidthTwo:
      setStateFromCssBlob(
        AD_UNIT_THEME.borders.borderWidthTwo().css.selector,
        AD_UNIT_THEME.borders.borderWidthTwo().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.borders.borderWidthTwo(),
    borderStyleTwo:
      setStateFromCssBlob(AD_UNIT_THEME.borders.borderStyleTwo, cssBlob)[0] ||
      AD_UNIT_THEME.borders.borderStyleTwo[0],
    borderColorThree:
      setStateFromCssBlob(
        AD_UNIT_THEME.borders.borderColorThree().css.selector,
        AD_UNIT_THEME.borders.borderColorThree().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.borders.borderColorThree(),
    borderWidthThree:
      setStateFromCssBlob(
        AD_UNIT_THEME.borders.borderWidthThree().css.selector,
        AD_UNIT_THEME.borders.borderWidthThree().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.borders.borderWidthThree(),
    borderStyleThree:
      setStateFromCssBlob(AD_UNIT_THEME.borders.borderStyleThree, cssBlob)[0] ||
      AD_UNIT_THEME.borders.borderStyleThree[0],
    borderColorFour:
      setStateFromCssBlob(
        AD_UNIT_THEME.borders.borderColorFour().css.selector,
        AD_UNIT_THEME.borders.borderColorFour().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.borders.borderColorFour(),
    borderWidthFour:
      setStateFromCssBlob(
        AD_UNIT_THEME.borders.borderWidthFour().css.selector,
        AD_UNIT_THEME.borders.borderWidthFour().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.borders.borderWidthFour(),
    borderStyleFour:
      setStateFromCssBlob(AD_UNIT_THEME.borders.borderStyleFour, cssBlob)[0] ||
      AD_UNIT_THEME.borders.borderStyleFour[0],
    footerBorderColor:
      setStateFromCssBlob(
        AD_UNIT_THEME.borders.footerBorderColor().css.selector,
        AD_UNIT_THEME.borders.footerBorderColor().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.borders.footerBorderColor(),
    footerBorderWidth:
      setStateFromCssBlob(
        AD_UNIT_THEME.borders.footerBorderWidth().css.selector,
        AD_UNIT_THEME.borders.footerBorderWidth().css.property,
        cssBlob
      )[0] || AD_UNIT_THEME.borders.footerBorderWidth(),
    footerBorderStyle:
      setStateFromCssBlob(
        AD_UNIT_THEME.borders.footerBorderStyle,
        cssBlob
      )[0] || AD_UNIT_THEME.borders.footerBorderStyle[0]
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case SELECT_MODAL_POSITION:
      return { ...state, modalPosition: action.payload, isEditing: true };
    case SELECT_CLOSE_BUTTON_POSITION:
      return { ...state, closeButtonPosition: action.payload, isEditing: true };
    case SELECT_CLOSE_BUTTON_COLOR:
      return { ...state, closeButtonColor: action.payload, isEditing: true };
    case SELECT_CLOSE_BUTTON_COLOR_STROKE:
      return {
        ...state,
        closeButtonColorStroke: action.payload,
        isEditing: true
      };
    case SELECT_MODAL_WIDTH:
      return { ...state, modalWidth: action.payload, isEditing: true };
    case SELECT_MODAL_CORNER_SHAPE:
      return { ...state, modalCornerShape: action.payload, isEditing: true };
    case SELECT_PAGE_OVERLAY_COLOR:
      return { ...state, pageOverlayColor: action.payload, isEditing: true };
    case SELECT_OFFER_BG_COLOR:
      return {
        ...state,
        offerBackgroundColor: action.payload,
        isEditing: true
      };
    case SELECT_OFFER_IMAGE_PLACEMENT:
      return {
        ...state,
        offerImagePlacement: action.payload,
        isEditing: true
      };
    case SELECT_OFFER_BLOCK_IMG_FIRST:
      return {
        ...state,
        offerBlockImgFirst: action.payload,
        isEditing: true
      };
    case SELECT_OFFER_BLOCK_IMG_REMAINING:
      return {
        ...state,
        offerBlockImgRemaining: action.payload,
        isEditing: true
      };
    case SELECT_OFFER_BLOCK_IMG_BORDER_COLOR:
      return {
        ...state,
        offerBlockImgBorderColor: action.payload,
        isEditing: true
      };
    case SELECT_OFFER_BLOCK_IMG_BORDER_WIDTH:
      return {
        ...state,
        offerBlockImgBorderWidth: action.payload,
        isEditing: true
      };
    case SELECT_OFFER_BLOCK_IMG_BORDER_STYLE:
      return {
        ...state,
        offerBlockImgBorderStyle: action.payload,
        isEditing: true
      };
    case SELECT_OFFER_BLOCK_IMG_BORDER_RADIUS:
      return {
        ...state,
        offerBlockImgBorderRadius: action.payload,
        isEditing: true
      };
    case SELECT_BUTTON_STYLE:
      return { ...state, buttonStyle: action.payload, isEditing: true };
    case SELECT_BUTTON_POSITIVE_TEXT_STYLE:
      return {
        ...state,
        buttonPositiveTextStyle: action.payload,
        isEditing: true
      };
    case SELECT_BUTTON_NEGATIVE_TEXT_STYLE:
      return {
        ...state,
        buttonNegativeTextStyle: action.payload,
        isEditing: true
      };
    case SELECT_BUTTON_NEUTRAL_TEXT_STYLE:
      return {
        ...state,
        buttonNeutralTextStyle: action.payload,
        isEditing: true
      };
    case SELECT_BUTTON_POSITIVE_TEXT_WEIGHT:
      return {
        ...state,
        buttonPositiveTextWeight: action.payload,
        isEditing: true
      };
    case SELECT_BUTTON_NEGATIVE_TEXT_WEIGHT:
      return {
        ...state,
        buttonNegativeTextWeight: action.payload,
        isEditing: true
      };
    case SELECT_BUTTON_NEUTRAL_TEXT_WEIGHT:
      return {
        ...state,
        buttonNeutralTextWeight: action.payload,
        isEditing: true
      };
    case SELECT_CTA_BUTTON_ORDER:
      return { ...state, buttonCtaOrder: action.payload, isEditing: true };
    case SELECT_BUTTON_POSITIVE_BG_COLOR:
      return {
        ...state,
        buttonPositiveBgColor: action.payload,
        isEditing: true
      };
    case SELECT_BUTTON_POSITIVE_TEXT_COLOR:
      return {
        ...state,
        buttonPositiveTextColor: action.payload,
        isEditing: true
      };
    case SELECT_BUTTON_POSITIVE_BORDER_COLOR:
      return {
        ...state,
        buttonPositiveBorderColor: action.payload,
        isEditing: true
      };
    case SELECT_BUTTON_POSITIVE_BORDER_WIDTH:
      return {
        ...state,
        buttonPositiveBorderWidth: action.payload,
        isEditing: true
      };
    case SELECT_BUTTON_POSITIVE_BORDER_STYLE:
      return {
        ...state,
        buttonPositiveBorderStyle: action.payload,
        isEditing: true
      };
    case SELECT_BUTTON_NEGATIVE_BORDER_STYLE:
      return {
        ...state,
        buttonNegativeBorderStyle: action.payload,
        isEditing: true
      };
    case SELECT_BUTTON_NEGATIVE_BG_COLOR:
      return {
        ...state,
        buttonNegativeBGColor: action.payload,
        isEditing: true
      };
    case SELECT_BUTTON_NEGATIVE_TEXT_COLOR:
      return {
        ...state,
        buttonNegativeTextColor: action.payload,
        isEditing: true
      };
    case SELECT_BUTTON_NEGATIVE_BORDER_COLOR:
      return {
        ...state,
        buttonNegativeBorderColor: action.payload,
        isEditing: true
      };
    case SELECT_BUTTON_NEGATIVE_BORDER_WIDTH:
      return {
        ...state,
        buttonNegativeBorderWidth: action.payload,
        isEditing: true
      };
    case SELECT_BUTTON_NEUTRAL_BORDER_STYLE:
      return {
        ...state,
        buttonNeutralBorderStyle: action.payload,
        isEditing: true
      };
    case SELECT_BUTTON_NEUTRAL_BG_COLOR:
      return {
        ...state,
        buttonNeutralBGColor: action.payload,
        isEditing: true
      };
    case SELECT_BUTTON_NEUTRAL_TEXT_COLOR:
      return {
        ...state,
        buttonNeutralTextColor: action.payload,
        isEditing: true
      };
    case SELECT_BUTTON_NEUTRAL_BORDER_COLOR:
      return {
        ...state,
        buttonNeutralBorderColor: action.payload,
        isEditing: true
      };
    case SELECT_BUTTON_NEUTRAL_BORDER_WIDTH:
      return {
        ...state,
        buttonNeutralBorderWidth: action.payload,
        isEditing: true
      };
    case SELECT_FONT_SIZE:
      return { ...state, fontSize: action.payload, isEditing: true };
    case SELECT_DEFAULT_FONT_FAMILY:
      return { ...state, defaultFontFamily: action.payload, isEditing: true };
    case SELECT_DEFAULT_FONT_COLOR:
      return { ...state, defaultFontColor: action.payload, isEditing: true };
    case SELECT_SUPPORTING_FONT_FAMILY:
      return {
        ...state,
        supportingFontFamily: action.payload,
        isEditing: true
      };
    case SELECT_SUPPORTING_FONT_COLOR:
      return { ...state, supportingFontColor: action.payload, isEditing: true };
    case SET_EDITING:
      return { ...state, isEditing: action.payload };
    case SELECT_BG_COLOR_ONE:
      return { ...state, backgroundColorOne: action.payload, isEditing: true };
    case SELECT_BG_COLOR_ONE_TEXT_COLOR:
      return {
        ...state,
        backgroundColorOneTextColor: action.payload,
        isEditing: true
      };
    case SELECT_BG_COLOR_TWO:
      return { ...state, backgroundColorTwo: action.payload, isEditing: true };
    case SELECT_BG_COLOR_TWO_TEXT_COLOR:
      return {
        ...state,
        backgroundColorTwoTextColor: action.payload,
        isEditing: true
      };
    case SELECT_BG_COLOR_THREE:
      return {
        ...state,
        backgroundColorThree: action.payload,
        isEditing: true
      };
    case SELECT_BG_COLOR_THREE_TEXT_COLOR:
      return {
        ...state,
        backgroundColorThreeTextColor: action.payload,
        isEditing: true
      };
    case SELECT_BG_COLOR_FOUR:
      return {
        ...state,
        backgroundColorFour: action.payload,
        isEditing: true
      };
    case SELECT_BG_COLOR_FOUR_TEXT_COLOR:
      return {
        ...state,
        backgroundColorFourTextColor: action.payload,
        isEditing: true
      };
    case SELECT_TEXT_COLOR_ONE:
      return { ...state, textColorOne: action.payload, isEditing: true };
    case SELECT_TEXT_COLOR_TWO:
      return { ...state, textColorTwo: action.payload, isEditing: true };
    case SELECT_TEXT_COLOR_THREE:
      return { ...state, textColorThree: action.payload, isEditing: true };
    case SELECT_BORDER_COLOR_ONE:
      return { ...state, borderColorOne: action.payload, isEditing: true };
    case SELECT_BORDER_WIDTH_ONE:
      return { ...state, borderWidthOne: action.payload, isEditing: true };
    case SELECT_BORDER_STYLE_ONE:
      return { ...state, borderStyleOne: action.payload, isEditing: true };
    case SELECT_BORDER_COLOR_TWO:
      return { ...state, borderColorTwo: action.payload, isEditing: true };
    case SELECT_BORDER_WIDTH_TWO:
      return { ...state, borderWidthTwo: action.payload, isEditing: true };
    case SELECT_BORDER_STYLE_TWO:
      return { ...state, borderStyleTwo: action.payload, isEditing: true };
    case SELECT_BORDER_COLOR_THREE:
      return { ...state, borderColorThree: action.payload, isEditing: true };
    case SELECT_BORDER_WIDTH_THREE:
      return { ...state, borderWidthThree: action.payload, isEditing: true };
    case SELECT_BORDER_STYLE_THREE:
      return { ...state, borderStyleThree: action.payload, isEditing: true };
    case SELECT_BORDER_COLOR_FOUR:
      return { ...state, borderColorFour: action.payload, isEditing: true };
    case SELECT_BORDER_WIDTH_FOUR:
      return { ...state, borderWidthFour: action.payload, isEditing: true };
    case SELECT_BORDER_STYLE_FOUR:
      return { ...state, borderStyleFour: action.payload, isEditing: true };
    case SELECT_FOOTER_BORDER_COLOR:
      return { ...state, footerBorderColor: action.payload, isEditing: true };
    case SELECT_FOOTER_BORDER_WIDTH:
      return { ...state, footerBorderWidth: action.payload, isEditing: true };
    case SELECT_FOOTER_BORDER_STYLE:
      return { ...state, footerBorderStyle: action.payload, isEditing: true };

    case SET_PREVIEW_MODE:
      return { ...state, previewMode: action.payload };
    default:
      return state;
  }
};

// Create the context
export const CustomThemeContext = createContext();

const AdunitTheme = ({ props }) => {
  const {
    item,
    modifyItem,
    canEdit,
    adUnitTemplates,
    customAdUnitTemplates,
    addMessage,
    removeToast
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState(item.customCss));
  const [openModal, setOpenModal] = useState(false);
  const [templateType, setTemplateType] = useState('');

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      let cssBlob = generateCssBlob(state);
      const frame = document.getElementById('previewFrame');
      if (state.previewMode === PREVIEW_MODE_MOBILE) {
        cssBlob += MOBILE_PREVIEW_CSS;
        frame.contentWindow.postMessage(
          {
            cssBlob,
            manualCssOverride: item.manualCssOverride,
            logoUrl: item.logoUrl ? item.logoUrl : '',
            mobileLogoUrl: item.mobileLogoUrl ? item.mobileLogoUrl : '',
            mobilePrimaryHeadline: item.mobilePrimaryHeadline
              ? item.mobilePrimaryHeadline
              : '',
            mobileSecondaryHeadline: item.mobileSecondaryHeadline
              ? item.mobileSecondaryHeadline
              : ''
          },
          '*'
        );
      } else {
        frame.contentWindow.postMessage(
          {
            cssBlob,
            manualCssOverride: item.manualCssOverride,
            logoUrl: item.logoUrl ? item.logoUrl : '',
            mobileLogoUrl: item.mobileLogoUrl ? item.mobileLogoUrl : '',
            primaryHeadline: item.primaryHeadline ? item.primaryHeadline : '',
            secondaryHeadline: item.secondaryHeadline
              ? item.secondaryHeadline
              : ''
          },
          '*'
        );
      }
    }, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    state,
    item.customCss,
    templateType,
    item.logoUrl,
    item.mobileLogoUrl,
    item.primaryHeadline,
    item.secondaryHeadline,
    item.manualCssOverride,
    item.mobilePrimaryHeadline,
    item.mobileSecondaryHeadline
  ]);

  useEffect(() => {
    if (!state.isEditing) return;
    const cssBlob = generateCssBlob(state);
    modifyItem({
      customCss: cssBlob
    });
    dispatch({ type: SET_EDITING, payload: false });
  }, [state, modifyItem]);

  const handlePreviewMode = mode => {
    const frame = document.getElementById('previewFrame');
    frame.style.margin = '0 auto';
    if (mode === PREVIEW_MODE_MOBILE) {
      frame.style.width = '380px';
    } else {
      frame.style.width = '100%';
    }
    dispatch({ type: SET_PREVIEW_MODE, payload: mode });
  };

  return (
    <>
      <Box
        margin={{ vertical: 'medium' }}
        border={{ side: 'top', color: 'border400' }}
        gridArea='auto/span 3'
      />

      <Box direction='row' gridArea='auto/span 3'>
        <ConfigurationTemplateButton
          adUnitTemplates={adUnitTemplates}
          customAdUnitTemplates={customAdUnitTemplates}
          embeddedFlowConfig={item}
          setOpenModal={setOpenModal}
          setTemplateType={setTemplateType}
        />
      </Box>

      <Box direction='row' align='center'>
        <Tip content='Preview mode does not impact the production view of adflow when saving the partner’s adflow settings.'>
          <Text
            size='medium'
            weight='bold'
            margin={{
              right: 'xsmall'
            }}
          >
            Preview Mode:
          </Text>
        </Tip>
        <Icon
          style={{
            marginRight: '5px',
            color: `${
              state.previewMode === PREVIEW_MODE_DESKTOP ? '#2A2EAC' : '#444'
            }`,
            opacity: `${
              state.previewMode === PREVIEW_MODE_DESKTOP ? '1' : '0.5'
            }`
          }}
          path={mdiMonitor}
          size={'24px'}
          cursor='pointer'
          onClick={() => handlePreviewMode(PREVIEW_MODE_DESKTOP)}
        />
        <Icon
          style={{
            color: `${
              state.previewMode === PREVIEW_MODE_MOBILE ? '#2A2EAC' : '#444'
            }`,
            opacity: `${
              state.previewMode === PREVIEW_MODE_MOBILE ? '1' : '0.5'
            }`
          }}
          path={mdiCellphone}
          size={'24px'}
          cursor='pointer'
          onClick={() => handlePreviewMode(PREVIEW_MODE_MOBILE)}
        />
      </Box>

      <Box direction='row' gridArea='auto/span 3' style={{ height: '80vh' }}>
        <AdunitPreview templateType={templateType} />
        <CustomThemeContext.Provider value={{ state, dispatch, canEdit }}>
          <AdunitThemePanel item={item} modifyItem={modifyItem} />
        </CustomThemeContext.Provider>
      </Box>

      {openModal && (
        <AdUnitTemplatePickerModal
          modifyItem={modifyItem}
          setModalOpen={setOpenModal}
          adUnitTemplates={adUnitTemplates}
          customAdUnitTemplates={customAdUnitTemplates}
          addMessage={addMessage}
          currentTemplate={item.adUnitTemplateId}
          wasLegacyTemplate={!!item.flowId}
          publishedFlowUrl={item.publishedFlowUrl}
          requestingUrlContainsFilters={item.requestingUrlContainsFilters}
          adUnitNumberOffers={item.adUnitNumberOffers}
          flowId={item.flowId}
          removeToast={removeToast}
        />
      )}
    </>
  );
};

export default AdunitTheme;
