import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import { cacheDirtyStateSlices } from './middleware';
import homeReducer from './home/home.reducers';
import toastsReducer from './toasts/toasts.reducers';
import pageReducer from './pages/page.reducers';
import contentBlockReducer from './content-blocks/content-block.reducers';
import surveyReducer from './survey/survey.reducers';
import * as itemTypes from './items/item-types';
import flowReducer from './flows/flow.reducers';
import userAttributeReducer from './user-attributes/user-attribute.reducers';
import rewardReducer from './rewards/reward.reducers';
import campaignReducer from './campaigns/performance/campaign.reducers';
import advertiserReducer from './advertisers/advertiser.reducers';
import integrationReducer from './advertisers/integration/integration.reducers';
import suppressionReducer from './advertisers/suppression/suppression.reducers';
import audienceReducer from './audiences/audience.reducers';
import audiencePriorityReducer from './audience-priorities/audience-priority.reducers';
import reportReducer from './reports/report.reducers';
import dataCampaignReducer from './campaigns/data/data-campaign.reducers';
import dataDeliveryReducer from './campaigns/data/delivery/data-delivery.reducers';
import jobCampaignReducer from './campaigns/job/job-campaign.reducers';
import callCampaignReducer from './campaigns/call/call-campaign.reducers';
import ruleReducer from './rules/rule.reducers';
import dataDeliveryGroupReducer from './campaigns/data/delivery-groups/delivery-group.reducers';
import trafficSourceReducer from './traffic/traffic-sources/traffic-source.reducers';
import trafficPartnerReducer from './traffic/traffic-partners/traffic-partner.reducers';
import campaignPlacementReducer from './campaigns/placement/campaign-placement.reducers';
import embeddedFlowConfigReducer from './traffic/traffic-partners/embedded-flow-config/embedded-flow-config.reducers';
import adCreativeReducer from './campaigns/performance/ad-creatives/ad-creative.reducers';
import adGroupReducer from './campaigns/performance/ad-groups/ad-group.reducers';
import offerSyndicationConfigReducer from './traffic/traffic-partners/offer-syndication-config/offer-syndication-config.reducers';

export const store = initial =>
  configureStore({
    preloadedState: initial ? initial : {},
    reducer: {
      home: homeReducer,
      toasts: toastsReducer,
      [itemTypes.FLOW]: flowReducer,
      [itemTypes.SURVEY]: surveyReducer,
      [itemTypes.PAGE]: pageReducer,
      [itemTypes.CONTENT_BLOCK]: contentBlockReducer,
      [itemTypes.USER_ATTRIBUTE]: userAttributeReducer,
      [itemTypes.REWARD]: rewardReducer,
      [itemTypes.CAMPAIGN]: campaignReducer,
      [itemTypes.ADVERTISER]: advertiserReducer,
      [itemTypes.INTEGRATION]: integrationReducer,
      [itemTypes.EMAIL_SUPPRESSION_LIST]: suppressionReducer,
      [itemTypes.AUDIENCE]: audienceReducer,
      [itemTypes.AUDIENCE_PRIORITY]: audiencePriorityReducer,
      [itemTypes.REPORT]: reportReducer,
      [itemTypes.DATA_CAMPAIGN]: dataCampaignReducer,
      [itemTypes.DATA_DELIVERY]: dataDeliveryReducer,
      [itemTypes.JOB_CAMPAIGN]: jobCampaignReducer,
      [itemTypes.CALL_CAMPAIGN]: callCampaignReducer,
      [itemTypes.RULE]: ruleReducer,
      [itemTypes.DATA_DELIVERY_GROUP]: dataDeliveryGroupReducer,
      [itemTypes.TRAFFIC_SOURCE]: trafficSourceReducer,
      [itemTypes.TRAFFIC_PARTNER]: trafficPartnerReducer,
      [itemTypes.CAMPAIGN_PLACEMENT]: campaignPlacementReducer,
      [itemTypes.EMBEDDED_FLOW_CONFIG]: embeddedFlowConfigReducer,
      [itemTypes.AD_CREATIVE]: adCreativeReducer,
      [itemTypes.AD_GROUP]: adGroupReducer,
      [itemTypes.OFFER_SYNDICATION_CONFIG]: offerSyndicationConfigReducer
    },
    middleware: [cacheDirtyStateSlices, ...getDefaultMiddleware()]
  });

export default store;
