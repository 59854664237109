import React, { useEffect, useState } from 'react';
import { FTSelect } from '../components/inputs';
import { fetchSuppressionList } from '../advertisers/suppression/suppression-utils';

const NO_DEFAULT_OPTION = {
  id: null,
  name: 'None'
};

const CustomerSuppressionSelector = ({
  emailSuppressionListId = '',
  advertiserId,
  disabled,
  modifyItem,
  addError
}) => {
  const [suppressionList, setSuppressionList] = useState([]);
  const [filteredSuppressionList, setFilteredSuppressionList] = useState([]);

  useEffect(() => {
    const getSuppressionList = async () => {
      try {
        const data = await fetchSuppressionList(advertiserId);
        setSuppressionList(data);
        setFilteredSuppressionList(data);
      } catch (error) {
        addError('Failed to fetch email suppression list');
      }
    };

    getSuppressionList();
  }, [advertiserId, addError]);

  return (
    <FTSelect
      disabled={disabled}
      label='Customer List'
      placeholder='Select Customer Suppression'
      value={suppressionList.find(sl => sl.id === emailSuppressionListId)}
      options={
        filteredSuppressionList.length
          ? [NO_DEFAULT_OPTION, ...filteredSuppressionList]
          : filteredSuppressionList
      }
      labelKey='name'
      valueKey='id'
      onChange={({ id }) => modifyItem({ emailSuppressionListId: id })}
      tipContent='Select the customer suppression list to apply to all Ad Groups within this campaign.'
      handleOpen={() => {
        setFilteredSuppressionList(suppressionList);
      }}
      handleSearch={search => {
        const lowercaseSearch = search.toLowerCase();
        if (search === '') {
          setFilteredSuppressionList(suppressionList);
        } else {
          setFilteredSuppressionList(
            suppressionList.filter(sl => {
              const lowercaseLabel = sl.name.toLowerCase();
              return lowercaseLabel.includes(lowercaseSearch);
            })
          );
        }
      }}
    />
  );
};

export default CustomerSuppressionSelector;
