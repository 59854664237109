import { Box, CheckBox, Text } from 'grommet';
import React, { useContext } from 'react';
import { FTSelect } from '../../../../../components/inputs';
import { CustomThemeContext } from './adunit-theme';
import {
  SELECT_MODAL_POSITION,
  SELECT_CLOSE_BUTTON_POSITION,
  SELECT_MODAL_WIDTH,
  SELECT_CLOSE_BUTTON_COLOR,
  SELECT_CLOSE_BUTTON_COLOR_STROKE,
  SELECT_MODAL_CORNER_SHAPE,
  AD_UNIT_THEME,
  SELECT_PAGE_OVERLAY_COLOR
} from './adunit-theme-constant';
import ColorPicker from '../../../../../components/color-picker';
import { parseCSSProperties } from '../../../traffic-partner-utils';

const AdunitModalSettings = ({ item, modifyItem }) => {
  const { state, dispatch, canEdit } = useContext(CustomThemeContext);
  return (
    <Box>
      <Box margin={{ vertical: 'xsmall' }}>
        <FTSelect
          disabled={!canEdit}
          label='Modal Position'
          placeholder='Modal Position'
          size='small'
          options={AD_UNIT_THEME.modalPosition}
          labelKey='displayName'
          valueKey='field'
          value={AD_UNIT_THEME.modalPosition.find(
            option => option.field === state.modalPosition.field
          )}
          onChange={option => {
            dispatch({
              type: SELECT_MODAL_POSITION,
              payload: option
            });
          }}
        />
      </Box>
      <Box margin={{ vertical: 'xsmall' }}>
        <FTSelect
          disabled={!canEdit}
          label='Close Button Position'
          placeholder='Close Button'
          size='small'
          options={AD_UNIT_THEME.closeButtonPosition}
          labelKey='displayName'
          valueKey='field'
          value={AD_UNIT_THEME.closeButtonPosition.find(
            option => option.field === state.closeButtonPosition.field
          )}
          onChange={option => {
            dispatch({
              type: SELECT_CLOSE_BUTTON_POSITION,
              payload: option
            });
          }}
        />
      </Box>
      <Box margin={{ vertical: 'xsmall' }}>
        <CheckBox
          label='Close on Outside Modal Click'
          disabled={!canEdit}
          checked={item.closeOnOutsideModalClick}
          onChange={event =>
            modifyItem({ closeOnOutsideModalClick: event.target.checked })
          }
        />
        <Text size='xsmall' color='dark-4' margin={{ top: 'xsmall' }}>
          *Enable this setting to allow end users to close the modal by clicking
          or tapping outside its boundaries. This feature is optional.
        </Text>
      </Box>
      <Box margin={{ vertical: 'xsmall' }}>
        <ColorPicker
          label='Close Button BG Color'
          color={parseCSSProperties(state.closeButtonColor.css.properties)}
          onChange={newValue => {
            dispatch({
              type: SELECT_CLOSE_BUTTON_COLOR,
              payload: AD_UNIT_THEME.closeButton.bgColor(newValue)
            });
          }}
          canEdit={canEdit}
        />
      </Box>
      <Box margin={{ vertical: 'xsmall' }}>
        <ColorPicker
          label='Close Button Stroke Color'
          color={parseCSSProperties(
            state.closeButtonColorStroke.css.properties
          )}
          onChange={newValue => {
            dispatch({
              type: SELECT_CLOSE_BUTTON_COLOR_STROKE,
              payload: AD_UNIT_THEME.closeButton.strokeColor(newValue)
            });
          }}
          canEdit={canEdit}
        />
      </Box>
      <Box margin={{ vertical: 'xsmall' }}>
        <FTSelect
          disabled={!canEdit}
          label='Modal Width'
          placeholder='Modal Width'
          size='small'
          options={AD_UNIT_THEME.modalWidth}
          labelKey='displayName'
          valueKey='field'
          value={AD_UNIT_THEME.modalWidth.find(
            option => option.field === state.modalWidth.field
          )}
          onChange={option => {
            dispatch({
              type: SELECT_MODAL_WIDTH,
              payload: option
            });
          }}
        />
      </Box>
      <Box margin={{ vertical: 'xsmall' }}>
        <FTSelect
          disabled={!canEdit}
          label='Modal Corner Shape'
          placeholder='Modal Corner Shape'
          size='small'
          options={AD_UNIT_THEME.modalCornerShape}
          labelKey='displayName'
          valueKey='field'
          value={AD_UNIT_THEME.modalCornerShape.find(
            option => option.field === state.modalCornerShape.field
          )}
          onChange={option => {
            dispatch({
              type: SELECT_MODAL_CORNER_SHAPE,
              payload: option
            });
          }}
        />
      </Box>
      <Box margin={{ vertical: 'xsmall' }}>
        <ColorPicker
          label='Page Overlay Color'
          color={parseCSSProperties(state.pageOverlayColor.css.properties)}
          onChange={newValue => {
            dispatch({
              type: SELECT_PAGE_OVERLAY_COLOR,
              payload: AD_UNIT_THEME.pageOverlayColor(newValue)
            });
          }}
          useOpacity={true}
          canEdit={canEdit}
        />
      </Box>
    </Box>
  );
};

export default AdunitModalSettings;
