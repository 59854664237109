import { authorizedFetch, isItemNew } from '../../utils';
import * as itemTypes from '../../items/item-types';
import {
  ALLOWED_FILE_TYPES,
  API_BASE_URL,
  IMPORT_STATUSES,
  STATUS_POLLING_INTERVAL
} from './suppression-constants';

// Create a new suppression item
export const createSuppressionItem = async (advertiserId, body) => {
  const response = await authorizedFetch(
    `${API_BASE_URL}/${advertiserId}/${itemTypes.EMAIL_SUPPRESSION_LIST}`,
    'POST',
    body
  );
  return response;
};

// Fetch the suppression list
export const fetchSuppressionList = async (
  advertiserId,
  body = {
    from: 0,
    size: 1000,
    orderByField: 'dateModified',
    orderByAsc: false
  }
) => {
  if (!isItemNew(advertiserId) && advertiserId) {
    const response = await authorizedFetch(
      `${API_BASE_URL}/${advertiserId}/${itemTypes.EMAIL_SUPPRESSION_LIST}/search`,
      'POST',
      body
    );
    return response.items || [];
  }
  return [];
};

// Fetch a single suppression item
export const fetchSuppressionItem = async (advertiserId, suppressionItemId) => {
  const response = await authorizedFetch(
    `${API_BASE_URL}/${advertiserId}/${itemTypes.EMAIL_SUPPRESSION_LIST}/${suppressionItemId}`
  );
  return response;
};

// Update the suppression item
export const updateSuppressionItem = async (
  advertiserId,
  suppressionItemId,
  body
) => {
  const response = await authorizedFetch(
    `${API_BASE_URL}/${advertiserId}/${itemTypes.EMAIL_SUPPRESSION_LIST}/${suppressionItemId}`,
    'PUT',
    body
  );
  return response;
};

// Recursively polls the suppression list until no items are in 'InProgress' or 'CleanUp' state
// This is used to monitor the status of file imports until they complete
export const monitorSuppressionImport = async (
  advertiserId,
  updateSuppressionList,
  suppressionId = null
) => {
  const data = await fetchSuppressionList(advertiserId);
  updateSuppressionList(data);

  const itemsToCheck = suppressionId
    ? data.filter(item => item.id === suppressionId)
    : data;

  const hasActiveImport = itemsToCheck.some(
    item =>
      item.importStatus === IMPORT_STATUSES.IN_PROGRESS ||
      item.importStatus === IMPORT_STATUSES.CLEAN_UP
  );

  if (hasActiveImport) {
    await new Promise(resolve => setTimeout(resolve, STATUS_POLLING_INTERVAL));
    return monitorSuppressionImport(
      advertiserId,
      updateSuppressionList,
      suppressionId
    );
  }
};

// Validate the file type
export const validateFile = file => {
  if (!ALLOWED_FILE_TYPES.includes(file.type)) {
    return 'Invalid file type. Please upload a CSV file.';
  }

  return null;
};
